import { withAlert } from "@components/alert/withAlert";
import { IListItem, ListItemContentRow } from "@components/objectList";
import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import BindingContext, { IEntity, TEntityKey } from "@odata/BindingContext";
import {
    EntitySetName,
    ICompanyBankAccountEntity,
    IInvoiceServiceIntegrationEntity
} from "@odata/GeneratedEntityTypes";
import {
    BackgroundJobStatusCode,
    BackgroundJobTypeCode,
    InvoiceServiceIntegrationTypeCode,
    WebSocketMessageTypeCode
} from "@odata/GeneratedEnums";
import { withOData } from "@odata/withOData";
import { IIntegrationsCustomData } from "@pages/integrations/Integration.types";
import {
    getIntegrations,
    INTEGRATION_SETTINGS_ACTION,
    INTEGRATION_SYNC_ACTION,
    INTEGRATIONS_API_URL
} from "@pages/integrations/Integration.utils";
import IntegrationFormView from "@pages/integrations/IntegrationFormView";
import IntegrationsSettingsDialog from "@pages/integrations/IntegrationSettingsDialog";
import { ObjectListAction } from "@pages/PageUtils";
import customFetch, { getDefaultPostParams } from "@utils/customFetch";
import { isDefined, isObjectEmpty } from "@utils/general";
import { TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { isBackgroundJobWebsocketMessage } from "@utils/websocketManager/Websocket.utils";
import WebsocketManager from "@utils/websocketManager/WebsocketManager";
import i18next from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { DefaultTheme } from "styled-components/macro";

import {
    getBackgroundJobFromWebsocketMessage,
    isRelevantBackgroundJob
} from "../../contexts/backgroundJobsContext/BackgroundJobsContext.utils";
import { QueryParam, Status, ToolbarItemType } from "../../enums";
import { getQueryParameters } from "../../routes/Routes.utils";
import DateType from "../../types/Date";
import DialogFormView from "../../views/formView/DialogFormView";
import { ObjectListView } from "../../views/ObjectListView";
import { getDefinitions } from "./Integration.def";

class Integration extends ObjectListView<IInvoiceServiceIntegrationEntity, IIntegrationsCustomData> {
    static defaultProps = {
        getDef: getDefinitions,
        entitySet: EntitySetName.InvoiceServiceIntegrations,
        translationPath: "Integrations:",
        id: EntitySetName.InvoiceServiceIntegrations
    };
    _formStorageId = `${EntitySetName.InvoiceServiceIntegrations}FormView`;
    formView = IntegrationFormView as unknown as typeof DialogFormView;
    _unsubscribeWebsocket: () => void;

    async componentDidMount() {
        await super.componentDidMount();
        this.refreshList();

        this._unsubscribeWebsocket = WebsocketManager.subscribe({
            callback: this.handleWebsocketMessage,
            types: [WebSocketMessageTypeCode.BackgroundJob]
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this._unsubscribeWebsocket?.();
    }

    get searchPlaceholder(): string {
        return this.props.t("Integrations:SearchPlaceholder");
    }

    handleWebsocketMessage = (message: TWebsocketMessage): void => {
        if (!isBackgroundJobWebsocketMessage(message)) {
            return;
        }

        const backgroundJob = getBackgroundJobFromWebsocketMessage(message, this.storage.context.getData().companies);
        const reloadTableJobTypes = [
            BackgroundJobTypeCode.ImportsDocumentsFromInvoiceServices,
            BackgroundJobTypeCode.ImportsDocumentsFromInvoiceService
        ];

        if (isRelevantBackgroundJob({
            backgroundJob,
            context: this.storage.context,
            relevantJobTypes: reloadTableJobTypes,
            relevantJobStatuses: [
                BackgroundJobStatusCode.Finished,
                BackgroundJobStatusCode.Error,
                BackgroundJobStatusCode.FinishedWithWarning,
            ]
        })) {
            this.refreshList();
        }
    };

    async onAfterSave(bc: BindingContext, refreshList?: boolean): Promise<void> {
        await this.refreshList();
    }

    async refreshList() {
        this.data = await getIntegrations(this.storage);
        this.forceUpdate();
    }

    getSaveMessage = (id: TEntityKey, isNew: boolean) => {
        const item = this.data?.find(item => item.Id === id);
        const tranString = isNew ? "Integrations:NewIntegrationSuccess" : "Integrations:EditIntegrationSuccess";

        return this.props.t(tranString, { name: item?.Name || "" });
    };

    onAfterDelete = async (item: ICompanyBankAccountEntity) => {
        await this.refreshList();

        this.props.setAlert({
            status: Status.Success,
            title: this.props.t("Components:Table.UpdateOk"),
            subTitle: this.props.t("Integrations:Deleted", { name: item?.Name || "" })
        });

        this.forceUpdate();
    };

    syncDocuments = async (integrationId: string): Promise<Response> => {
        const url = `${INTEGRATIONS_API_URL}/SyncDocuments/${integrationId}`;
        return await customFetch(url, {
            ...getDefaultPostParams()
        });
    };

    handleCustomAction = async (itemId: TEntityKey, type: string): Promise<void> => {
        if (type === INTEGRATION_SETTINGS_ACTION) {
            this.setUrl(itemId.toString(), null, { [QueryParam.Action]: INTEGRATION_SETTINGS_ACTION });
        } else if (type === INTEGRATION_SYNC_ACTION) {
            await this.syncDocuments(itemId.toString());
        }
    };

    getItemColor = (isActive: boolean): keyof DefaultTheme => {
        return isActive ? "C_SEM_text_good" : "C_SEM_text_bad";
    };

    isConnected = (integration: IInvoiceServiceIntegrationEntity): boolean => {
        return isDefined(integration.Parameters?.AccessTokenId) && !isObjectEmpty(integration.Settings);
    };

    getApiStatus = (integration: IInvoiceServiceIntegrationEntity): { color: keyof DefaultTheme, title: string } => {
        const hasToken = this.isConnected(integration);
        return {
            color: this.getItemColor(hasToken),
            title: hasToken ? i18next.t("Common:General.Connected") : i18next.t("Common:General.Disconnected")
        };
    };

    getIconName = (item: IInvoiceServiceIntegrationEntity): string => {
        if (item.InvoiceServiceIntegrationTypeCode === InvoiceServiceIntegrationTypeCode.Fakturoid) {
            return "Fakturoid";
        } else if (item.InvoiceServiceIntegrationTypeCode === InvoiceServiceIntegrationTypeCode.IDoklad) {
            return "IDoklad";
        }
        return "BankApi";
    };

    getListActions = (item: IInvoiceServiceIntegrationEntity): IToolbarItem[] => {
        return [{
            id: ObjectListAction.Edit,
            iconName: "Edit",
            itemType: ToolbarItemType.Icon,
            label: this.props.t("Common:General.Edit")
        }, {
            id: ObjectListAction.Delete,
            iconName: "Bin",
            itemType: ToolbarItemType.Icon,
            label: this.props.t("Common:General.Delete")
        }, {
            id: INTEGRATION_SETTINGS_ACTION,
            iconName: "BankApi",
            itemType: ToolbarItemType.Icon,
            label: this.props.t("Integrations:SetConnection")
        }, ...(this.isConnected(item) ? [{
            id: INTEGRATION_SYNC_ACTION,
            iconName: "Refresh",
            itemType: ToolbarItemType.Icon,
            label: this.props.t("Integrations:SyncDocuments")
        }] : [])
        ];
    }

    createItems(items: IInvoiceServiceIntegrationEntity[]): IListItem[] {
        return (items || []).map((item, idx) => {
            const id = item.Id?.toString();
            return {
                id,
                // if we scroll manually to the listItem, we need to use index as a key,
                // so react don't scroll automatically (without animation)
                key: !!this._scrollToBc ? idx.toString() : id,
                name: item.Name,
                iconName: this.getIconName(item),
                iconTitle: item.Name,
                actions: this.getListActions(item),
                contents: this.renderContents(item)
            };
        });
    }

    renderContents = (integration: IInvoiceServiceIntegrationEntity) => {
        const apiStatus = this.getApiStatus(integration);

        return [
            {
                content:
                        <>
                            <ListItemContentRow
                                    hasSpaceAfter
                                    color={apiStatus.color}
                                    label={this.props.t("Integrations:State")}
                                    text={apiStatus.title}/>
                            {!!integration.DateLastRun && <ListItemContentRow
                                    label={this.props.t("Integrations:LastSync")}
                                    text={DateType.format(integration.DateLastRun)}/>}
                            {this.isConnected(integration) && <ListItemContentRow
                                    label={this.props.t("Integrations:ImportRange")}
                                    text={integration.Settings?.IntegrationApiImportFrequency?.Name}/>
                            }
                        </>
            }
        ];
    };

    handleAfterConfirmDialog = async (savedData: IEntity, isConnected: boolean): Promise<void> => {
        if (savedData) {
            const key = savedData.Id;
            this.handleCloseDialog();
            await this.refreshList();
            const item = this.data?.find(item => item.Id === key);
            this.showMessage(Status.Success, this.props.t(`Integrations:${isConnected ? "IntegrationWasSet" : "IntegrationWasDisconnected"}`, { name: item?.Name }));
            this.scrollToItem(key);

            if (isConnected) {
                await this.syncDocuments(key);
            }
        }
    };

    customRender = () => {
        const action = getQueryParameters()?.[QueryParam.Action];
        if (action === INTEGRATION_SETTINGS_ACTION && this.storage.loaded) {
            return <IntegrationsSettingsDialog
                    storage={this.storage}
                    onAfterConfirm={this.handleAfterConfirmDialog}
                    onClose={this.handleCloseDialog}
            />;
        }
        return null;
    };
}

export default withAlert()(withRouter(withTranslation([...getDefinitions.translationFiles, "Common"])(withOData(Integration))));