import { DateRangeSpecialValue } from "@components/inputs/date/popup/Calendar.utils";
import { SwitchType } from "@components/inputs/switch/Switch";
import {
    ifAll,
    ifAny,
    IFieldDefFn,
    IGetValueArgs,
    TInfoValue,
    TTemporalDialogSettings,
    TValidatorFn
} from "@components/smart/FieldInfo";
import {
    BirthNumberFieldDef,
    getAddressFields,
    PhoneNumberDef,
    SingleBusinessPartnerDef
} from "@components/smart/GeneralFieldDefinition";
import {
    CompanyEntity,
    CompanyLegalAddressEntity,
    CompanyVatReducedCoefficientEntity,
    CompanyVatStatusEntity,
    EntitySetName,
    EntityTypeName,
    ICompanyEntity,
    ICompanyVatStatusEntity,
    IVatStatusEntity,
    TaxStatementSignatoryPersonEntity
} from "@odata/GeneratedEntityTypes";
import {
    AccountingCode,
    CountryCode,
    TypeOfBusinessCode,
    VatStatementFrequencyCode,
    VatStatusCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumNameSpaceName, getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { getTemporalPropertyExtraFieldContentAfter, isTemporalFieldReadOnly } from "@odata/TemporalUtils";
import { companyIsSecondaryAddressPath } from "@pages/companies/Company.shared.utils";
import {
    getTaxStatementSignatoryPersonFieldDefs,
    hasTaxStatementSignatoryPersonPath
} from "@pages/companies/TaxStatementSignatoryPerson.utils";
import i18next from "i18next";
import React from "react";
import { TestContext } from "yup";

import ImageUploader from "../../components/imageUploader/ImageUploader";
import Field from "../../components/inputs/field/Field";
import {
    BasicInputSizes,
    CacheStrategy,
    FastEntryInputSizes,
    FieldType,
    GroupedField,
    LabelStatus,
    TextAlign,
    ValidatorType
} from "../../enums";
import { TValue } from "../../global.types";
import BindingContext, { createPath } from "../../odata/BindingContext";
import { DATE_MIN, getUtcDate, getUtcDayjs } from "../../types/Date";
import { IFormDef } from "../../views/formView/Form";
import { FormStorage } from "../../views/formView/FormStorage";
import { setDefByEntityType } from "../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../PageUtils";
import { getFinancialAdministrationDef } from "../payroll/governmentInstitution/GovernmentInstitution.utils";
import { StampText } from "./Company.styles";
import {
    currentVatStatus,
    currentVatStatusFrequency,
    getVatDeductionCoefficientCurrentYear,
    ICompanyExtendedEntity,
    ICompanyFormCustomData,
    stampPath
} from "./Company.utils";
import CompanyFormAvatarWithName from "./CompanyFormAvatarWithName";

export const VatReducedDeductionCoefficient = BindingContext.localContext("VatReducedDeductionCoefficient");

export const isSecondaryAddressVisible = (args: IGetValueArgs): boolean => {
    return !!args.storage.getValueByPath(companyIsSecondaryAddressPath);
};

const getVatStatusIndex = (args: IGetValueArgs): number => {
    // used for both line items (FastEntry) and fields => choose correct bc first
    const bc = args.bindingContext.getPath(true) === CompanyEntity.VatStatuses ? args.bindingContext : args.bindingContext.getParent();
    const company = args.storage.getEntity<ICompanyEntity>();
    const vatStatuses = company.VatStatuses ?? [];
    const itemIndex = vatStatuses.findIndex(vatStatus => {
        const vatStatusBc = bc.removeKey().addKey(vatStatus);

        return bc.isSame(vatStatusBc);
    });

    return itemIndex;
};

const isFirstVatStatus: TInfoValue<boolean> = (args: IGetValueArgs) => {
    const itemIndex = getVatStatusIndex(args);

    return itemIndex === 0;
};

const isNotLastVatStatus: TInfoValue<boolean> = (args: IGetValueArgs) => {
    const itemIndex = getVatStatusIndex(args);
    const company = args.storage.getEntity<ICompanyEntity>();
    const vatStatuses = company.VatStatuses;

    return itemIndex !== vatStatuses.length - 1;
};

const isVatRegistered: TInfoValue<boolean> = (args: IGetValueArgs) => {
    const vatStatusCode: VatStatusCode = args.storage.getValueByPath(currentVatStatus);

    return vatStatusCode === VatStatusCode.VATRegistered;
};

const isNonVatRegistered: TInfoValue<boolean> = (args: IGetValueArgs) => {
    const vatStatusCode: VatStatusCode = args.storage.getValueByPath(currentVatStatus);

    return vatStatusCode === VatStatusCode.NotVATRegistered;
};

const getValidatorItems = (args: IGetValueArgs) => {
    const item = args.storage.getValue(args.bindingContext.getParent()) as ICompanyVatStatusEntity;
    const items = args.storage.getValue(args.bindingContext.getParent().removeKey());
    const itemIndex = items.findIndex((i: ICompanyVatStatusEntity) => i === item);
    const previousItem = items[itemIndex - 1];


    return {
        item, items, itemIndex, previousItem
    };
};

const validatorVatFrequencyDate = (args: IGetValueArgs, validationVatFrequencyCode: VatStatementFrequencyCode) => {
    const { item, previousItem } = getValidatorItems(args);

    const previousVatStatusCode = previousItem?.VatStatusCode as VatStatusCode;
    const vatFrequencyCode = item?.VatStatementFrequencyCode as VatStatementFrequencyCode;
    const previousVatFrequencyCode = previousItem?.VatStatementFrequencyCode as VatStatementFrequencyCode;

    if (
        [VatStatusCode.NotVATRegistered, VatStatusCode.IdentifiedPerson].includes(previousVatStatusCode)
        || [VatStatusCode.NotVATRegistered, VatStatusCode.IdentifiedPerson].includes(item.VatStatusCode as VatStatusCode)
        || vatFrequencyCode === null
        || vatFrequencyCode === previousVatFrequencyCode
        || vatFrequencyCode !== validationVatFrequencyCode
    ) {
        return true;
    }

    const dateValidFrom = getUtcDayjs(item.DateValidFrom);

    return dateValidFrom.date() === 1 && dateValidFrom.month() === 0;
};

const validatorVatFrequencyQuarterlyDate: TValidatorFn = (value: TValue, args: IGetValueArgs, testContext?: TestContext) => {
    return validatorVatFrequencyDate(args, VatStatementFrequencyCode.Quarterly);
};

const validatorVatFrequencyMonthlyDate: TValidatorFn = (value: TValue, args: IGetValueArgs, testContext?: TestContext) => {
    return validatorVatFrequencyDate(args, VatStatementFrequencyCode.Monthly);
};

export const partnerAresFields = [
    {
        from: "Name",
        to: CompanyEntity.LegalName
    },
    {
        from: "LegalNumber",
        to: CompanyEntity.LegalNumber
    },
    {
        from: "TaxNumber",
        to: CompanyEntity.TaxNumber
    },
    {
        from: "BillingAddress/Street",
        to: createPath(CompanyEntity.LegalAddress, CompanyLegalAddressEntity.Street)
    },
    {
        from: "BillingAddress/City",
        to: createPath(CompanyEntity.LegalAddress, CompanyLegalAddressEntity.City)
    },
    {
        from: "BillingAddress/Country",
        to: createPath(CompanyEntity.LegalAddress, CompanyLegalAddressEntity.Country)
    },
    {
        from: "BillingAddress/PostalCode",
        to: createPath(CompanyEntity.LegalAddress, CompanyLegalAddressEntity.PostalCode)
    },
    {
        from: "MainActivity",
        to: CompanyEntity.CzNace
    }
];

const CompanySingleBusinessPartnerDef = {
    ...SingleBusinessPartnerDef,
    columns: [{
        id: CompanyEntity.LegalName
    }, {
        id: CompanyEntity.LegalNumber
    }]
};

const isEdit = (args: IGetValueArgs) => {
    return !args.storage.data.bindingContext.isNew();
};

const vatExtraFieldContentAfter = (onlyIfNotVatRegistered: boolean) => {
    return (args: IGetValueArgs) => {
        // don't show when creating new company
        // or when currentVatStatusFrequency is visible (and has the extra content)
        if (args.bindingContext.getParent().isNew() || (onlyIfNotVatRegistered && isVatRegistered(args)) || (args.storage as FormStorage).isReadOnly) {
            return null;
        }

        return getTemporalPropertyExtraFieldContentAfter(CompanyEntity.VatStatuses, CompanyVatStatusEntity.VatStatementFrequency)(args);
    };
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const entitySet = "Companies";
    const legalAddressFields = getAddressFields("LegalAddress");
    const communicationAddressFields = getAddressFields("CommunicationAddress", { isVisible: isSecondaryAddressVisible });

    const temporalDialogSettings: TTemporalDialogSettings = {
        dialogTitle: i18next.t("Companies:Form.VatStatusPlanDialog"),
        columns: [
            CompanyVatStatusEntity.VatStatus,
            CompanyVatStatusEntity.VatStatementFrequency
        ],
        granularity: "day",
        withoutGaps: true,
        cannotBeEnded: true,
        onlyOneFuturePlan: true
    };

    const financialAdministrationDef = getFinancialAdministrationDef();

    const form: IFormDef = {
        id: `${EntityTypeName.Company}Form`,
        isReadOnly: (args: IGetValueArgs) => !(args.storage as FormStorage<ICompanyEntity, ICompanyFormCustomData>).getCustomData().hasPermission,
        translationFiles: getDefinitions.translationFiles,
        fieldDefinition: {
            LegalName: {
                ...CompanySingleBusinessPartnerDef,
                groupedField: GroupedField.MultiStart,
                width: BasicInputSizes.XL,
                affectedFields: [{ id: createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.VatStatementFrequency) }]
            },
            LegalNumber: {
                ...CompanySingleBusinessPartnerDef,
                groupedField: GroupedField.MultiEnd,
                width: BasicInputSizes.S,
                affectedFields: [{ id: createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.VatStatementFrequency) }]
            },
            TaxNumber: {
                width: BasicInputSizes.M,
                isRequired: (args: IGetValueArgs<boolean>) => {
                    const entity = args.storage.getEntity<ICompanyExtendedEntity>();

                    if (entity.TypeOfBusinessCode === TypeOfBusinessCode.LegalPerson) {
                        return true;
                    }

                    return entity[currentVatStatus] === VatStatusCode.IdentifiedPerson;
                }
            },
            BirthNumber: {
                ...BirthNumberFieldDef,
                width: BasicInputSizes.M,
                isVisible: (args: IGetValueArgs) => {
                    const storage = args.storage as FormStorage<ICompanyEntity>;
                    const entity = storage.getEntity();

                    return entity.TypeOfBusinessCode === TypeOfBusinessCode.NaturalPerson && entity.AccountingCode === AccountingCode.CashBasisAccounting;
                }
            },
            ...legalAddressFields,
            "LegalAddress/Country": {
                ...legalAddressFields["LegalAddress/Country"],
                width: BasicInputSizes.L,
                defaultValue: CountryCode.CzechRepublic
            },
            ...communicationAddressFields,
            "CommunicationAddress/Country": {
                ...communicationAddressFields["CommunicationAddress/Country"],
                width: BasicInputSizes.L
            },
            [companyIsSecondaryAddressPath]: {
                label: i18next.t("Companies:Form.AddAnotherAddress"),
                type: FieldType.Switch,
                fieldSettings: { type: SwitchType.YesNo },
                affectedFields: [
                    { id: "CommunicationAddress/Street" }, { id: "CommunicationAddress/City" },
                    { id: "CommunicationAddress/PostalCode" }, { id: "CommunicationAddress/Country" }
                ]
            },
            "CommunicationContact/FirstName": {
                groupedField: GroupedField.MultiStart,
                isRequired: true
            },
            "CommunicationContact/LastName": {
                groupedField: GroupedField.MultiEnd,
                isRequired: true
            },
            "CommunicationContact/Email": {
                width: BasicInputSizes.XL,
                validator: {
                    type: ValidatorType.Email
                },
                isRequired: true
            },
            "CommunicationContact/PhoneNumber": {
                ...PhoneNumberDef,
                isRequired: true
            },
            [currentVatStatus]: {
                type: FieldType.ComboBox,
                isRequired: true,
                label: i18next.t("Companies:Form.CurrentVatStatus"),
                defaultValue: VatStatusCode.VATRegistered,
                isDisabled: (args: IGetValueArgs) => {
                    return !args.bindingContext.getParent().isNew();
                },
                fieldSettings: {
                    items: getEnumSelectItems(EntityTypeName.VatStatus)
                },
                groupedField: (args: IGetValueArgs) => {
                    // only MultiStart if frequency is visible
                    if (isVatRegistered(args)) {
                        return GroupedField.MultiStart;
                    }

                    return null;
                },
                additionalProperties: [
                    { id: `/${createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.DateValidFrom)}` },
                    { id: `/${createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.DateValidTo)}` },
                    { id: `/${createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.VatStatus)}` },
                    { id: `/${createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.VatStatementFrequency)}` }
                ],
                affectedFields: [
                    { id: currentVatStatusFrequency },
                    { id: VatReducedDeductionCoefficient }
                ],
                extraFieldContentAfter: vatExtraFieldContentAfter(true)
            },
            [currentVatStatusFrequency]: {
                type: FieldType.ComboBox,
                isRequired: true,
                label: i18next.t("Companies:Form.CurrentVatStatusFrequency"),
                defaultValue: VatStatementFrequencyCode.Monthly,
                isVisible: isVatRegistered,
                isDisabled: (args: IGetValueArgs) => {
                    return !args.bindingContext.getParent().isNew();
                },
                groupedField: GroupedField.MultiEnd,
                fieldSettings: {
                    entitySet: EntitySetName.VatStatementFrequencies,
                    displayName: "Name",
                    items: getEnumSelectItems(EntityTypeName.VatStatementFrequency)
                },
                extraFieldContentAfter: vatExtraFieldContentAfter(false)
            },
            [CompanyEntity.VatStatuses]: {},
            [createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.VatStatus)]: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.EndOfTime,
                defaultValue: VatStatusCode.VATRegistered,
                useForValidation: true,
                fieldSettings: {
                    displayName: "Name",
                    items: getEnumSelectItems(EntityTypeName.VatStatus),
                    temporalDialog: temporalDialogSettings
                },
                isDisabled: isNotLastVatStatus,
                affectedFields: [{ id: createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.VatStatementFrequency) }],
                width: FastEntryInputSizes.M,
                groupedField: GroupedField.MultiStart
            },
            [createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.VatStatementFrequency)]: {
                type: FieldType.ComboBox,
                defaultValue: VatStatementFrequencyCode.Monthly,
                useForValidation: true,
                fieldSettings: {
                    displayName: "Name",
                    temporalDialog: temporalDialogSettings
                },
                formatter: (val: TValue, args: IFormatOptions) => {
                    const vatStatus = args.storage.getValue(args.bindingContext.getParent()) as ICompanyVatStatusEntity;

                    return vatStatus?.VatStatusCode === VatStatusCode.VATRegistered ? getEnumDisplayValue(EntityTypeName.VatStatementFrequency, vatStatus?.VatStatementFrequency?.Code) : "";
                },
                isDisabled: (args: IGetValueArgs) => {
                    const vatStatusBc = args.bindingContext.getParent().navigate(CompanyVatStatusEntity.VatStatus);
                    const vatStatus = args.storage.getValue(vatStatusBc) as IVatStatusEntity;
                    const { previousItem } = getValidatorItems(args);

                    return isNotLastVatStatus(args) || vatStatus?.Code !== VatStatusCode.VATRegistered
                        || (vatStatus?.Code === VatStatusCode.VATRegistered && previousItem?.VatStatusCode === VatStatusCode.NotVATRegistered);
                },
                groupedField: GroupedField.MultiEnd
            },
            [createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.DateValidFrom)]: {
                defaultValue: (args: IGetValueArgs) => {
                    // first VatStatus is expected to have some really old value
                    return args.storage.data.bindingContext.isNew() ? DATE_MIN : getUtcDate();
                },
                isDisabled: isNotLastVatStatus,
                isReadOnly: ifAny(isFirstVatStatus, isTemporalFieldReadOnly),
                width: FastEntryInputSizes.M,
                useForValidation: true,
                fieldSettings: {
                    showSpecialValue: DateRangeSpecialValue.WithoutStart
                },
                validator: {
                    type: ValidatorType.Date,
                    settings: {
                        min: 0,
                        customValidator: [
                            {
                                validator: validatorVatFrequencyQuarterlyDate,
                                message: i18next.t("Error:CompanyVatStatusSwitchFromMonthFrequencyToQuarterDateIsInvalid")
                            },
                            {
                                validator: validatorVatFrequencyMonthlyDate,
                                message: i18next.t("Error:CompanyVatStatusSwitchFromQuarterFrequencyToMonthDateIsInvalid")
                            }
                        ]
                    }
                }
            },
            [createPath(CompanyEntity.VatStatuses, CompanyVatStatusEntity.DateValidTo)]: {
                width: FastEntryInputSizes.M,
                isDisabled: isFirstVatStatus,
                isReadOnly: isTemporalFieldReadOnly,
                useForValidation: true,
                fieldSettings: {
                    showSpecialValue: DateRangeSpecialValue.WithoutEnd
                }
            },
            TypeOfBusiness: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.EndOfTime,
                defaultValue: TypeOfBusinessCode.LegalPerson,
                fieldSettings: {
                    displayName: "Name"
                },
                affectedFields: [
                    { id: "BirthNumber" },
                    { id: "Accounting" },
                    { id: "TaxNumber" }
                ]
            },
            CzNace: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.EndOfTime,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    displayName: "Description",
                    preloadItems: true
                },
                columns: [
                    { id: "Description" },
                    { id: "Code" }
                ]
            },
            [BindingContext.localContext("Edition")]: {
                label: i18next.t("Companies:Form.Edition"),
                type: FieldType.ComboBox,
                isRequired: true,
                isDisabled: true,
                isReadOnly: isEdit,
                defaultValue: "czech",
                fieldSettings: {
                    items: [
                        {
                            id: "czech",
                            label: i18next.t("Companies:Form.CzechEdition")
                        }
                    ]
                }
            },
            Accounting: {
                defaultValue: AccountingCode.AccountingForBusiness,
                fieldSettings: {
                    displayName: "Name"
                },
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.EndOfTime,
                width: BasicInputSizes.L,
                isDisabled: (args: IGetValueArgs) => {
                    const storage = args.storage as FormStorage<ICompanyEntity>;

                    return storage.getEntity().TypeOfBusinessCode === TypeOfBusinessCode.LegalPerson;
                },
                affectedFields: [
                    { id: "BirthNumber" }
                ]
            },
            CompanyRegister: {
                type: FieldType.EditableText,
                width: BasicInputSizes.XL,
                isConfirmable: true,
                fieldSettings: {
                    placeholder: i18next.t("Companies:Form.CompanyRegisterPlaceholder")
                }
            },
            [CompanyEntity.FinancialAdministration]: {
                ...financialAdministrationDef,
                width: BasicInputSizes.L,
                label: i18next.t("Companies:Form.FinancialAdministration"),
                additionalProperties: [{ id: "Ufo" }, { id: "DataBoxId" }],
                fieldSettings: {
                    ...financialAdministrationDef.fieldSettings,
                    noRecordText: i18next.t("Common:Select.NoRecord")
                }
            },
            [stampPath]: {
                type: FieldType.Custom,
                render: (args: IFieldDefFn) => {
                    const image = (args.storage as FormStorage<ICompanyEntity, ICompanyFormCustomData>).getCustomData().stampImage;

                    return (
                        <Field label={i18next.t("Companies:Form.StampLabel")} name={"Stamp"}>
                            <StampText image={image}>
                                {i18next.t("Companies:Form.StampDescription")}
                            </StampText>
                            <ImageUploader image={image}
                                           onImageChange={(image: File) => {
                                               args.storage.setCustomData({
                                                   stampImage: image
                                               });
                                               args.storage.addActiveField(args.storage.data.bindingContext.navigate(stampPath));
                                               args.storage.refreshFields();
                                           }}/>
                        </Field>
                    );
                }
            },
            [CompanyEntity.Name]: {
                type: FieldType.WriteLine,
                labelStatus: LabelStatus.Hidden,
                isRequired: false,
                isConfirmable: true,
                fieldSettings: {
                    placeholder: i18next.t("Companies:Form.NameWatermark")
                },
                textAlign: TextAlign.Left,
                width: BasicInputSizes.L,
                render: ({ storage, fieldElement }: IFieldDefFn) => {
                    return (
                        <CompanyFormAvatarWithName storage={storage as FormStorage} fieldElement={fieldElement}/>
                    );
                }
            },
            [CompanyEntity.UseVatReducedDeduction]: {
                type: FieldType.Switch,
                label: i18next.t("Companies:Form.VatReducedDeduction"),
                isVisible: ifAll(isVatRegistered, isEdit),
                affectedFields: [{ id: VatReducedDeductionCoefficient }]
            },
            [VatReducedDeductionCoefficient]: {
                type: FieldType.NumberInput,
                isRequired: true,
                label: i18next.t("Companies:Form.Value"),
                tooltip: () => i18next.t("Companies:Form.ValueTooltip", { year: getVatDeductionCoefficientCurrentYear() }),
                width: BasicInputSizes.S,
                fieldSettings: {
                    unit: "%",
                    showSteppers: true,
                    min: 0,
                    max: 100
                },
                validator: {
                    type: ValidatorType.Integer,
                    settings: {
                        min: 0,
                        max: 100
                    }
                },
                additionalProperties: [
                    { id: createPath(CompanyEntity.VatReducedCoefficients, CompanyVatReducedCoefficientEntity.Year) },
                    { id: createPath(CompanyEntity.VatReducedCoefficients, CompanyVatReducedCoefficientEntity.VatReducedCoefficient) }
                ],
                isVisible: (args: IGetValueArgs<ICompanyEntity>) => !!args.storage?.data.entity?.UseVatReducedDeduction && isVatRegistered(args)
            },
            ...(getTaxStatementSignatoryPersonFieldDefs())
        },
        groups: [
            {
                id: "header",
                rows: [[{ id: CompanyEntity.Name }]]
            },
            {
                id: "main",
                rows: [
                    [{ id: "LegalName" }, { id: "LegalNumber" }, { id: "TaxNumber" }],
                    [{ id: "LegalAddress/Street" }, { id: "LegalAddress/City" }, { id: "LegalAddress/PostalCode" }, { id: "LegalAddress/Country" }],
                    [{ id: companyIsSecondaryAddressPath }],
                    [{ id: "CommunicationAddress/Street" }, { id: "CommunicationAddress/City" }, { id: "CommunicationAddress/PostalCode" }, { id: "CommunicationAddress/Country" }]
                ]
            },
            {
                id: "Contact",
                title: i18next.t("Companies:Form.Contact"),
                rows: [[{ id: "CommunicationContact/FirstName" }, { id: "CommunicationContact/LastName" }, { id: "CommunicationContact/PhoneNumber" }, { id: "CommunicationContact/Email" }]]
            },
            {
                id: "Settings",
                title: i18next.t("Companies:Form.Settings"),
                rows: [
                    [{ id: "TypeOfBusiness" }, { id: "Accounting" }, { id: "BirthNumber" }],
                    [{ id: currentVatStatus }, { id: currentVatStatusFrequency }, { id: CompanyEntity.UseVatReducedDeduction }, { id: VatReducedDeductionCoefficient }],
                    [{ id: "CzNace" }],
                    [{ id: CompanyEntity.FinancialAdministration }],
                    [{ id: "CompanyRegister" }]
                ]
            },
            {
                id: "TaxStatementSignatoryPerson",
                togglePropPath: hasTaxStatementSignatoryPersonPath,
                isVisible: (args: IGetValueArgs) => {
                    return !args.storage?.data.bindingContext.isNew() && !args.storage.context.getAddingNewCompany();
                },
                title: i18next.t("Companies:Form.TaxStatementSignatoryPerson"),
                rows: [[{
                    id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxStatementSubmitter)
                }, {
                    id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonType)
                }],
                    [{
                        id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalPersonName)
                    }, {
                        id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalNumber)
                    }],
                    [{
                        id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.FirstName)
                    }, {
                        id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LastName)
                    }],
                    [{
                        id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.RelationshipToTaxSubject)
                    }],
                    [{
                        id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.DateOfBirth)
                    }, {
                        id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxAdvisorRegistrationNumber)
                    }],
                    [{
                        id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.SignatoryPerson)
                    }]
                ]
            }
        ],
        additionalProperties: [{
            id: "Name",
            useForValidation: true
        }, { id: "Logo" }, { id: "Stamp" }]
    };

    return {
        entitySet,
        form
    };
};

getDefinitions.translationFiles = ["Companies", "Common", "Components", "Error", getEnumNameSpaceName(EntityTypeName.VatStatementFrequency), getEnumNameSpaceName(EntityTypeName.VatStatus)];
setDefByEntityType(EntityTypeName.Company, getDefinitions);