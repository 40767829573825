import { ISelectItem } from "@components/inputs/select/Select.types";
import { IGetValueArgs, TGetValueFn } from "@components/smart/FieldInfo";
import { ICompanyPermissionEntity, IUserEntity } from "@odata/GeneratedEntityTypes";
import { UserStatusCode } from "@odata/GeneratedEnums";
import { initials } from "@utils/string";
import { cloneDeep } from "lodash";
import React from "react";
import { DefaultTheme } from "styled-components";

import UserInitial from "../../../components/token/UserInitial";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { TRecordString } from "../../../global.types";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage, IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";

export const CompaniesPath = "##companies##";
export const CompanyRolesPermissionsOverviewPath = BindingContext.localContext("CompanyRolesPermissionsOverview");
export const RoleIdPath = "##roleId##";
export const GeneralRolesPath = BindingContext.localContext("GeneralRoles");
export const CustomerRolesPath = BindingContext.localContext("CustomerRoles");
export const GeneralRolePermissionsOverviewPath = BindingContext.localContext("GeneralRolePermissionsOverview");

export const COMPANY_ROLE_LINES_PATH = "##companyRoleLines##";

export const OwnerGeneralRoleId = -1;
export const CustomerCompanyRoleId = -2;
export const CustomerGeneralRoleId = -3;
export const invitationInfoPath = BindingContext.localContext("InvitationInfo");
export const ownerRoleConfirmInfoPath = BindingContext.localContext("OwnerRoleConfirmInfo");
export const activePath = BindingContext.localContext("Active");

interface ICompanyRoleLineItem {
    [RoleIdPath]: number;
    [CompaniesPath]: number[];
}

export interface IUserEntityExtended extends IUserEntity {
    [COMPANY_ROLE_LINES_PATH]: ICompanyRoleLineItem[]
}

export interface IPermissionsWithCompanies extends ICompanyPermissionEntity {
    companies: number[];
}

export interface IPermissionItemProps {
    iconName: string;
    agendas: string;
    permission: IPermissionsWithCompanies;
    allCompanies: TRecordString;
}

export interface IUsersFormCustomData extends IFormStorageDefaultCustomData {
    companyRolesSelectItems?: ISelectItem<number>[];
    permissions: IPermissionsWithCompanies[];
}

export type TUsersFormStorage = FormStorage<IUserEntityExtended, IUsersFormCustomData>;

export function isEntityOwner(context: IAppContext, entity: IEntity): boolean {
    return !entity?.CreatedBy?.Id || entity?.CreatedBy?.Id === context.getData()?.userSettings?.Id;
}

/** According to spec, user initials should always be just two letters, even if user has multiple names. */
export const getUserNameInitials = (user: IUserEntity): string => {
    if (!user) {
        return "";
    }

    return initials(`${user.FirstName} ${user.LastName}`);
};

export function isAgendaCustomer(context: IAppContext, user: IUserEntity): boolean {
    const companyId = context.getCompanyId();
    if (!companyId) {
        return false;
    }
    const currentRole = user?.CompanyRoles?.find(role => role.Company?.Id === companyId);
    return currentRole?.CompanyRole?.Id === CustomerCompanyRoleId;
}

export const currentUserIsCustomer = memoizeOne((context: IAppContext): boolean => {
    return isAgendaCustomer(context, context.getData().userSettings);
});

export interface IEmailInfoDetail {
    Email: string;
    DateEmailSent: Date;
}

export const getUserStatusColor = (status: UserStatusCode): keyof DefaultTheme => {
    switch (status) {
        case UserStatusCode.Active:
            return "C_SEM_text_good";
        case UserStatusCode.Invited:
        case UserStatusCode.RoleAcceptancePending:
            return "C_SEM_text_warning";
        default:
            return "C_TEXT_primary";
    }
};

export interface INamedPerson {
    FirstName?: string;
    LastName?: string;
}

export const universalPersonNameFormatter = (person: INamedPerson): string => {
    const { FirstName, LastName } = person ?? {};
    const name = [FirstName ?? "", LastName ?? ""].join(" ").trim();

    return name;
};

const userInitialStyles: React.CSSProperties = { marginRight: "8px", position: "relative", top: "-1px" };

export function transformUserSelectItems(items: ISelectItem[], args: IGetValueArgs): ISelectItem[] {
    return items.map(item => {
        const person = item.additionalData as INamedPerson;
        const name = universalPersonNameFormatter(person);
        const shortCut = getUserNameInitials(person);

        return {
            ...item,
            itemContent: (<UserInitial title={shortCut} tooltip={name} style={userInitialStyles} isBig={false}/>)
        };
    });
}

export const isUserOwner = (user: IUserEntity): boolean => {
    return user?.GeneralRoles?.[0]?.GeneralRole.Id === OwnerGeneralRoleId;
};

export const isUserCustomer = (user: IUserEntity): boolean => {
    return user?.GeneralRoles?.[0]?.GeneralRole.Id === CustomerGeneralRoleId;
};

export const isCustomerGeneralRole: TGetValueFn<boolean> = (args) => {
    const { storage } = args;
    const generalRole = storage.data.entity?.GeneralRoles?.[0]?.GeneralRole?.Id;
    return generalRole === CustomerGeneralRoleId;
};

export function getCompanyRoleSelectItems(storage: TUsersFormStorage, selectedRoleId?: number): ISelectItem[] {
    const { companyRolesSelectItems } = storage.getCustomData();
    const isCustomer = isCustomerGeneralRole({ storage });

    const items = (cloneDeep(companyRolesSelectItems) ?? [])
            .filter(item => isCustomer ? item.id === CustomerCompanyRoleId : item.id !== CustomerCompanyRoleId);

    const lines = storage.getValueByPath(COMPANY_ROLE_LINES_PATH);
    if (lines?.length) {
        // disable used items, so they can't be selected again
        for (const line of storage.getValueByPath(COMPANY_ROLE_LINES_PATH)) {
            if (line[RoleIdPath] === selectedRoleId) {
                continue;
            }
            const item = items.find(item => item.id === line[RoleIdPath]);
            if (item) {
                item.isDisabled = true;
            }
        }
    }
    return items;
}