import { IValueInterval } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString, IDayInterval } from "@components/inputs/date/utils";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { getSimpleBoolSelectItems, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportHierarchy, IReportRowDef, IReportRowFormatterArgs } from "@components/smart/smartTable";
import { IRowValues } from "@components/table";
import { IFormatOptions } from "@odata/OData.utils";
import { CUSTOM_DATE_RANGE_ID } from "@pages/reports/customFilterComponents/ComposedDateRange.utils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { QUnitType } from "dayjs";
import i18next from "i18next";
import * as yup from "yup";

import { BasicInputSizes, FieldType, ReportTableRowType, Sort } from "../../../enums";
import { TRecordAny, TRecordValue, TValue } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import { ROUTE_ACCOUNTING_JOURNAL } from "../../../routes";
import { formatCurrency } from "../../../types/Currency";
import { getUtcDayjs } from "../../../types/Date";
import { byDateValidationSchema, CommonReportProps, getByDateDef, getDateRangeDef } from "../CommonDefs";
import {
    composedDateRangeOnBeforeLoadCallback,
    getDateRangeCustomValueDef
} from "../customFilterComponents/ComposedDateRange";
import { IFiscalYearDependentReportCustomData } from "../FiscalYearDepenedentReport";
import {
    compareColumnIdsByTimeAggFuncSpecificity,
    getLabelDrilldownFilters,
    IReportDateRange,
    IReportTableDefinition,
    NumberAggregationFunction,
    ReportColumnType,
    ReportConfigGroup,
    TGetReportConfigListItemOverride
} from "../Report.utils";
import { ReportId } from "../ReportIds";
import { ReportStorage } from "../ReportStorage";

export const GeneralLedgerProps = {
    showAccountTypes: BindingContext.localContext("ShowAccountTypes"),
    showAccountsWithoutRevenue: BindingContext.localContext("ShowAccountsWithoutRevenue"),
    splitIntoPeriods: BindingContext.localContext("SplitIntoPeriods")
};

export enum SplitIntoPeriods {
    None = "None",
    Months = "Months",
    Periods = "Periods"
}

export enum GeneralLedgerAccountTypes {
    Synth = "Synth",
    SynthOffBal = "SynthOffBal",
    SynthOffBalAnal = "SynthOffBalAnal"
}

const parseAccountNumber = (fullAccountName: string) => {
    // filter unfortunately doesn't work with whole account name, so we set just it's number to filter
    return fullAccountName?.toString().split(" ")[0];
};

const parseFilters = (accFilterName: string, rowValues: IRowValues) => {
    const filters: TRecordAny = {
        ...getLabelDrilldownFilters(rowValues)
    };

    if (rowValues.AccountChildrenNames) {
        // AccountChildrenNames contains account itself
        filters[BindingContext.localContext(accFilterName)] = rowValues.AccountChildrenNames;
    } else if (rowValues.Account) {

        filters[BindingContext.localContext(accFilterName)] = parseAccountNumber(rowValues.Account as string);
    }

    // there can be multiple JournalEntry_DateAccountingTransaction with different agg fns in the hierarchy
    // find the most specific one and use it for drilldown
    const dateAccTransIds = Object.keys(rowValues).filter(key => key.startsWith("JournalEntry_DateAccountingTransaction_"));

    // add filter for DateAccountingTransaction
    if (dateAccTransIds.length > 0) {
        dateAccTransIds.sort(compareColumnIdsByTimeAggFuncSpecificity);
        const value = rowValues[dateAccTransIds[0]];

        if (value) {
            const period = dateAccTransIds[0].substring("JournalEntry_DateAccountingTransaction_".length).toUpperCase();
            // dayJs typescript interface for these functions expect QUnitType which doesn't contain "week"
            // as option, but in docs there is that possibility and it works just fine with "week" option
            const from = formatDateToDateString(getUtcDayjs(value.toString()).startOf(period as QUnitType));
            const to = formatDateToDateString(getUtcDayjs(value.toString()).endOf(period as QUnitType));
            filters[BindingContext.localContext("JournalEntry_DateAccountingTransaction")] = { from, to, type: period };
        }
    }

    return filters;
};

export const getDefinition = (): IReportTableDefinition => {
    const tableId = ReportId.GeneralLedger;
    const title = i18next.t("Reporting:GeneralLedger.Title");
    const path = "GeneralLedger";
    const initialSortBy = [{ id: "Account", sort: Sort.Asc }];
    const parameters = [
        CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue,
        CommonReportProps.byDate,
        GeneralLedgerProps.showAccountTypes,
        GeneralLedgerProps.showAccountsWithoutRevenue,
        GeneralLedgerProps.splitIntoPeriods
    ];

    const enhanceRowsWithChildrenAccountIds = (rowsData: IReportRowDef[], args: IReportRowFormatterArgs, parentAccountNames?: Set<string>): Set<string> => {
        // includes account itself
        const accountNames: Set<string> = new Set();
        const showAccountTypes = args.settings[GeneralLedgerProps.showAccountTypes] as string;

        if (showAccountTypes !== GeneralLedgerAccountTypes.SynthOffBalAnal && parentAccountNames) {
            // propagate account names from parent to one level down
            parentAccountNames.forEach(accountName => accountNames.add(accountName));
        }

        for (const row of rowsData) {
            const rowAccountNames: Set<string> = new Set();

            if (showAccountTypes !== GeneralLedgerAccountTypes.SynthOffBalAnal
                && parentAccountNames && (!row.Rows || row.Rows.length === 0)) {
                parentAccountNames.forEach(accountName => rowAccountNames.add(accountName));
            }

            if (row.Value.Account) {
                const rowAccountName = parseAccountNumber(row.Value.Account as string);
                rowAccountNames.add(rowAccountName);
            }

            if (row.Value.AccountChildrenNames) {
                for (const accountName of (row.Value.AccountChildrenNames as string[])) {
                    rowAccountNames.add(accountName);
                }
            }

            rowAccountNames.forEach(accountName => accountNames.add(accountName));

            if (row.Rows) {
                const childrenAccountName = enhanceRowsWithChildrenAccountIds(row.Rows, args, rowAccountNames);

                row.Value.AccountChildrenNames = Array.from(childrenAccountName) as string[];
                childrenAccountName.forEach((accountName => rowAccountNames.add(accountName)));
            }

            if ([ReportTableRowType.Total, ReportTableRowType.GrandTotal].includes(row.Type)) {
                row.Value.AccountChildrenNames = Array.from(accountNames) as string[];
            } else {
                row.Value.AccountChildrenNames = Array.from(rowAccountNames) as string[];
            }

        }

        return accountNames;
    };

    const rowsDataFactory = (rowsData: IReportRowDef[], args: IReportRowFormatterArgs): IReportRowDef[] => {
        enhanceRowsWithChildrenAccountIds(rowsData, args);

        const showAccountsWithoutRevenue = args.storage.data.entity[GeneralLedgerProps.showAccountsWithoutRevenue];

        if (!showAccountsWithoutRevenue) {
            return rowsData;
        }

        return rowsData.map((rowData) => {
            if (rowData.Type !== ReportTableRowType.Group || rowData.Rows?.length > 0) {
                return rowData;
            }

            const values = { ...rowData.Value };

            // add 0kč to all currency columns of the empty group rows
            for (const col of args.columns) {
                if (!values[col.id] && col.type === ReportColumnType.Currency) {
                    values[col.id] = 0;
                }
            }

            return {
                ...rowData,
                Value: values
            };
        });
    };

    const columnOverrides = (columnId: string) => {
        if (columnId.startsWith("JournalEntry_CreditAmount") || columnId.startsWith("JournalEntry_DebitAmount")) {
            return {
                formatter: (val: TValue, args: IFormatOptions) => {
                    let filters: TRecordValue;

                    if (columnId.startsWith("JournalEntry_CreditAmount")) {
                        filters = parseFilters("CreditAccount_Number", args.entity);
                    } else if (columnId.startsWith("JournalEntry_DebitAmount")) {
                        filters = parseFilters("DebitAccount_Number", args.entity);
                    }

                    const formattedVal = formatCurrency(val, args.entity["JournalEntry_CurrencyCode"] ?? getCompanyCurrency(args.context));

                    if (val === 0) {
                        return formattedVal;
                    }

                    const storage = (args.storage as ReportStorage<IFiscalYearDependentReportCustomData>);
                    const settings = storage.settings;
                    const byDate = settings?.[CommonReportProps.byDate];
                    const dateRangeBc = args.storage.data.bindingContext.navigate(CommonReportProps.dateRange);
                    const dateRange = args.storage.getValue(dateRangeBc);
                    const dateRangeInterval = storage.data.customData.dateRanges.find((dr: IReportDateRange) => dr.Key === dateRange).Value;
                    // use custom date range, if the byDate param doesn't fit the "DateEnd" part of the selected date range
                    const isCustomRange = !getUtcDayjs(dateRangeInterval.DateEnd).isSame(byDate);
                    const dateRangeParam: IValueInterval = {
                        from: formatDateToDateString(dateRangeInterval.DateStart),
                        to: formatDateToDateString(byDate)
                    };

                    filters[CommonReportProps.dateRange] = isCustomRange ? CUSTOM_DATE_RANGE_ID : dateRange;
                    filters[CommonReportProps.dateRangeCustomValue] = dateRangeParam;

                    return getTableDrillDownLink(formattedVal, {
                        route: ROUTE_ACCOUNTING_JOURNAL,
                        context: args.storage.context,
                        storage: args.storage,
                        filters
                    });
                }
            };

        }
        return null;
    };

    const canExpandCollapseRows = (storage: ReportStorage) => {
        // special case, the table rows are nested even though there is only one Group in report hierarchy
        // ==> we need to enable the expand/collapse button
        return storage.data.entity[GeneralLedgerProps.showAccountTypes] === "SynthOffBalAnal";
    };

    const configListItemsOverride: TGetReportConfigListItemOverride = {
        Account: {
            isRequired: true,
            isDisabled: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Groups
        },
        JournalEntry_DateAccountingTransaction: {
            isRequired: true,
            isDisabled: true,
            isCopyOnly: false,
            areItemsReadOnly: true,
            hideFromAvailable: true,
            group: ReportConfigGroup.Groups
        },
        InitialDebit: {
            isRequired: true,
            isDisabled: true,
            areItemsReadOnly: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Aggregations
        },
        InitialCredit: {
            isRequired: true,
            isDisabled: true,
            areItemsReadOnly: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Aggregations
        },
        JournalEntry_DebitAmount: {
            isRequired: true,
            isDisabled: true,
            areItemsReadOnly: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Aggregations
        },
        JournalEntry_CreditAmount: {
            isRequired: true,
            isDisabled: true,
            areItemsReadOnly: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Aggregations
        },
        FinalDebit: {
            isRequired: true,
            isDisabled: true,
            areItemsReadOnly: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Aggregations
        },
        FinalCredit: {
            isRequired: true,
            isDisabled: true,
            areItemsReadOnly: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Aggregations
        },
        BalanceDebit: {
            isRequired: true,
            isDisabled: true,
            areItemsReadOnly: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Aggregations
        },
        BalanceCredit: {
            isRequired: true,
            isDisabled: true,
            areItemsReadOnly: true,
            isCopyOnly: false,
            group: ReportConfigGroup.Aggregations
        }
    };

    const disableAggregateButton = true;
    const defaultReportHierarchy: IReportHierarchy = {
        "Aggregate": true,
        "Groups": [
            {
                "ColumnAlias": "Account"
            }
        ],
        "Columns": [],
        "Aggregations": [
            {
                "ColumnAlias": "InitialDebit",
                "AggregationFunction": NumberAggregationFunction.Sum
            },
            {
                "ColumnAlias": "InitialCredit",
                "AggregationFunction": NumberAggregationFunction.Sum
            },
            {
                "ColumnAlias": "JournalEntry_DebitAmount",
                "AggregationFunction": NumberAggregationFunction.Sum
            },
            {
                "ColumnAlias": "JournalEntry_CreditAmount",
                "AggregationFunction": NumberAggregationFunction.Sum
            },
            {
                "ColumnAlias": "FinalDebit",
                "AggregationFunction": NumberAggregationFunction.Sum
            },
            {
                "ColumnAlias": "FinalCredit",
                "AggregationFunction": NumberAggregationFunction.Sum
            },
            {
                "ColumnAlias": "BalanceDebit",
                "AggregationFunction": NumberAggregationFunction.Sum
            },
            {
                "ColumnAlias": "BalanceCredit",
                "AggregationFunction": NumberAggregationFunction.Sum
            }
        ]

    };

    const byDateDef = getByDateDef();
    const dateRangeDef = getDateRangeDef();
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue,
                CommonReportProps.byDate,
                GeneralLedgerProps.showAccountTypes,
                GeneralLedgerProps.showAccountsWithoutRevenue,
                GeneralLedgerProps.splitIntoPeriods
            ],
            filterDefinition: {
                [CommonReportProps.dateRange]: {
                    ...dateRangeDef,
                    fieldSettings: {
                        ...dateRangeDef.fieldSettings,
                        transformFetchedItems: (items: ISelectItem[]): ISelectItem[] => {
                            return items.filter(item => !item.id.toString().includes("FiscalPeriod"));
                        }
                    }
                },
                [CommonReportProps.dateRangeCustomValue]: getDateRangeCustomValueDef(),
                [CommonReportProps.byDate]: {
                    ...byDateDef,
                    validator: {
                        ...byDateDef.validator,
                        settings: {
                            customSchema: (args: IGetValueArgs) => {
                                return byDateValidationSchema
                                    .test("isInRange", i18next.t("Reporting:GeneralLedger.ByDateRangeError"),
                                        function(this: yup.TestContext, value: TValue) {
                                            const dateRangeBc = args.storage.data.bindingContext.navigate(CommonReportProps.dateRange);
                                            const dateRange = args.storage.getValue(dateRangeBc);
                                            const dateRangeInfo = args.storage.getInfo(dateRangeBc);
                                            const dateRangeItem = dateRangeInfo.fieldSettings?.items?.find(item => item.id === dateRange);
                                            const byDate = getUtcDayjs(value as Date);
                                            const range = dateRangeItem?.additionalData?.dateRange as IDayInterval;

                                            return byDate.isBetween(range?.from, range?.to, "day", "[]");
                                        });
                            }
                        }
                    }
                },
                [GeneralLedgerProps.showAccountTypes]: {
                    type: FieldType.ComboBox,
                    defaultValue: GeneralLedgerAccountTypes.Synth,
                    label: i18next.t("Reporting:GeneralLedger.ShownAccounts"),
                    fieldSettings: {
                        items: [
                            {
                                label: i18next.t("Reporting:GeneralLedger.Synth"),
                                id: GeneralLedgerAccountTypes.Synth
                            },
                            {
                                label: i18next.t("Reporting:GeneralLedger.SynthOffBal"),
                                id: GeneralLedgerAccountTypes.SynthOffBal
                            },
                            {
                                label: i18next.t("Reporting:GeneralLedger.SynthOffBalAnal"),
                                id: GeneralLedgerAccountTypes.SynthOffBalAnal
                            }
                        ]
                    }
                },
                [GeneralLedgerProps.showAccountsWithoutRevenue]: {
                    type: FieldType.ComboBox,
                    label: i18next.t("Reporting:GeneralLedger.ShowWithoutRevenue"),
                    width: BasicInputSizes.L,
                    defaultValue: false,
                    fieldSettings: {
                        items: getSimpleBoolSelectItems()
                    }
                },
                [GeneralLedgerProps.splitIntoPeriods]: {
                    type: FieldType.ComboBox,
                    label: i18next.t("Reporting:GeneralLedger.Split"),
                    width: BasicInputSizes.M,
                    defaultValue: SplitIntoPeriods.None,
                    fieldSettings: {
                        items: [
                            { id: SplitIntoPeriods.None, label: i18next.t("Reporting:GeneralLedger.SplitNone") },
                            { id: SplitIntoPeriods.Months, label: i18next.t("Reporting:GeneralLedger.SplitMonths") }
                        ]
                    }
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {}
        }
    ];

    const onBeforeLoad = async (storage: ReportStorage) => {
        await composedDateRangeOnBeforeLoadCallback(storage, false, false);
    };

    return {
        title, initialSortBy, id: tableId, path,
        rowsDataFactory, configListItemsOverride, onBeforeLoad,
        disableAggregateButton, defaultReportHierarchy,
        filterBarDef, parameters, columnOverrides, canExpandCollapseRows
    };
};