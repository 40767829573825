import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { setDefByEntityType } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import { getCashReceiptsFormDef, getDefinitions as cashReceiptCommonDef } from "./CashReceiptsDef";
import CashReceiptsIssuedFormView from "./CashReceiptsIssuedFormView";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const definition = cloneDeep(getCashReceiptsFormDef(CashReceiptsIssuedFormView, false, context));
    return definition;
};

getDefinitions.translationFiles = cashReceiptCommonDef.translationFiles;
setDefByEntityType(EntityTypeName.CashReceiptIssued, getDefinitions);