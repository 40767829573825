import styled, { css } from "styled-components/macro";

import { Status } from "../../enums";
import { getSemanticTextColor, Title } from "../../global.style";
import { IconButton } from "../button";
import { IconWrapper as ToolbarIconWrapper, StyledToolbar } from "../toolbar/Toolbar.styles";

export const HEADER_GAP = 32;

export const TOP_PADDING = 4;

export const IconWrapper = styled(IconButton)`
    position: relative;
    width: 38px;
    display: flex;
    justify-content: center;
`;

export const StyledHeader = styled.div`
    position: relative;
    padding: 17px 0 42px;
`;

export const TitleWrapper = styled.div`
`;

export const HeaderSubtitle = styled(Title)<{ status?: Status }>`
    margin-left: 12px;
    font-size: 20px;

    ${props => props.status && css<{ status?: Status }>`
        font-size: 18px;
        font-style: italic;
        font-weight: normal;
        margin-left: 12px;
        color: ${props => getSemanticTextColor(props.theme, props.status)};
    `};
`;

export const StyledHeaderContent = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: ${HEADER_GAP}px 0;
    position: relative;
    width: 100%;
`;

export const HeaderToolbarWrapper = styled.div`
    position: relative;
    padding-top: ${TOP_PADDING}px;

    ${StyledToolbar} {
        margin-bottom: 0;
    }

    ${ToolbarIconWrapper} {
        margin-left: 2px;
    }
`;

export const ToolbarSpacer = styled.div`
    min-width: 24px;
    max-width: 24px;
`;
