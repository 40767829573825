import { ISelectItem } from "@components/inputs/select/Select.types";
import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import { isDefined } from "@utils/general";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { FileAction, ToolbarItemType } from "../../enums";
import { TRecordType, TValue } from "../../global.types";
import OtherFolderWindow from "../../pages/inbox/OtherFolderWindow";
import TestIds from "../../testIds";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { IHandleCustomActionArgs } from "../../views/table/TableView";
import { getConfirmationActionText } from "../../views/table/TableView.render.utils";
import { WithBusyIndicator, withBusyIndicator } from "../busyIndicator/withBusyIndicator";
import { IconButton } from "../button";
import FileUploadButton from "../fileUploadButton/FileUploadButton";
import FocusManager, { IFocusableItemProps } from "../focusManager/FocusManager";
import { BinIcon, FolderOtherIcon } from "../icon";
import { TId } from "../table";
import { Toolbar } from "../toolbar/Toolbar";
import File, { IFile } from "./File";
import { retrieveEntries, retrieveFile } from "./File.utils";
import FileDropArea from "./FileDropArea";
import FileGrid, { IFileGridProps } from "./FileGrid";
import FileList, { IFileListProps } from "./FileList";
import { StyledFileUploader } from "./FileUploader.styles";

export interface IFileActionEvent {
    id: TId;
    action: FileAction | string; // string for custom file actions
}

export enum FileUploaderLayout {
    Grid = "Grid",
    List = "List"
}

const LAYOUT_TOGGLE_BUTTON = "LayoutToggleButton";

interface IProps extends WithTranslation, WithBusyIndicator {
    files: IFile[];
    customDescription?: string;
    // accepted file extensions
    accept?: string;
    // accepts multiple files by default
    multiple?: boolean;
    /** Whether the file uploader listens for file over/drop events only on its DropArea or on document */
    isLocalDropArea?: boolean;
    isReadOnly?: boolean;
    disableOtherFolder?: boolean;
    isDeleteDisabled?: boolean;
    /** When file uploader lurks in a shadows, waiting for a file dropped somewhere in the whole window but not revealing it self */
    isHidden?: boolean;
    /** Items appended to the file context menu*/
    customFileActions?: ISelectItem[] | ((fileId: TId) => ISelectItem[]);
    onNewFiles?: (files: File[]) => void;
    onAttachInboxFiles?: (inboxFiles: TId[]) => Promise<boolean>;
    onRemoveFiles?: (files: TId[]) => void;
    onFileAction?: (args: IFileActionEvent) => void;
    onFileClick?: (fileId: TId) => void;

    onDragEnter?: (event: DragEvent) => void;
    onDragLeave?: (event: DragEvent) => void;

    className?: string;
    style?: React.CSSProperties;

    layout?: FileUploaderLayout;
    layoutComponentProps?: Partial<IFileGridProps | IFileListProps>;
    customToolbarContent?: IToolbarItem;
    onCustomToolbarAction?: (action: string, args: IHandleCustomActionArgs) => void;
    onRemoveActionToggle?: (state: boolean) => void;
    /** List of elements, that handles file drop for themselves,
     * and when user hovers files over them, in case this FileDropArea is global,
     * we should disable hover state in this FileDropArea */
    otherFileDropAreas?: HTMLElement[];

    hideInboxFileIds?: number[];
}

interface IState {
    removing: boolean;
    removingFiles: TRecordType<boolean>;
    showOtherFolder: boolean;
    showGrid: boolean;
}

class FileUploader extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    static defaultProps: Partial<IProps> = {
        multiple: true
    };

    constructor(props: IProps, context: IAppContext) {
        super(props);
        const savedLayout = context.getData().personalizationSettings?.fileUploaderLayout;
        this.state = {
            removing: false,
            removingFiles: {},
            showOtherFolder: false,
            showGrid: savedLayout !== FileUploaderLayout.List
        };
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.props.files.length === 0 && prevProps.files.length > 0) {
            this.stopRemoving();
        }
    }

    // traverse folder structure to retrieve all files
    getAllFiles = async (item: any, path = ""): Promise<File[]> => {
        let files: File[] = [];

        if (item?.isFile) {
            files.push(await retrieveFile(item));
        } else if (item?.isDirectory) {
            const dirReader = item.createReader();
            const entries = await retrieveEntries(dirReader);

            for (let i = 0; i < entries.length; i++) {
                files = [...files, ...await this.getAllFiles(entries[i], path + item.name + "/")];
            }
        }

        return files;
    };

    handleConfirmRemoveClick = () => {
        const files = Object.keys(this.state.removingFiles)
            .filter(fileId => this.state.removingFiles[fileId])
            .map(fileId => {
                return this.getOriginalFileId(fileId);
            });

        if (files.length > 0) {
            this.props.onRemoveFiles?.(files);
        }

        this.stopRemoving();
    };

    stopRemoving = () => {
        this.setState({
            removing: false,
            removingFiles: {}
        });
        this.props.onRemoveActionToggle?.(false);
    };

    handleRemoveButtonClick = () => {
        this.setState((state: IState) => {
            return {
                removing: true,
                removingFiles: !state.removing ? {} : state.removingFiles
            };
        });
        this.props.onRemoveActionToggle?.(true);
    };

    handleShowOtherFolder = () => {
        this.setState({ showOtherFolder: true });
    };

    handleCloseOtherFolder = () => {
        this.setState({ showOtherFolder: false });
    };

    // get original id, before we called toString on it
    getOriginalFileId = (fileId: TId) => {
        return this.props.files.find(file => file.id.toString() === fileId.toString()).id;
    };

    handleContextMenuSelection = (item: ISelectItem, fileId: TId) => {
        const fileAction = item.id as FileAction;
        this.props.onFileAction?.({
            id: this.getOriginalFileId(fileId),
            action: fileAction
        });
    };

    handleFileRemovingAction = (fileIds: TId[], forceState?: boolean) => {
        this.setState((state: IState) => {
            let removingFiles: TRecordType<boolean>;
            if (isDefined(forceState)) {
                // toggleAll
                removingFiles = {};
                fileIds.forEach(fileId => {
                    removingFiles[fileId.toString()] = forceState;
                });
            } else {
                removingFiles = { ...state.removingFiles };
                fileIds.forEach(fileId => {
                    const strId = fileId.toString();
                    if (state.removingFiles[strId]) {
                        delete removingFiles[strId];
                    } else {
                        removingFiles[strId] = true;
                    }
                });
            }

            return {
                removingFiles
            };
        });
    };

    handleFileClick = (fileId: TId) => {
        if (!this.state.removing) {
            this.props.onFileClick?.(fileId);
        }
    };

    renderContent = (focusableProps: IFocusableItemProps) => {
        if (!this.props.files?.length) {
            return null;
        }
        const Component = this.layoutComponent;
        return (
            <Component focusableProps={focusableProps}
                       files={this.props.files}
                       isReadOnly={this.props.isReadOnly}
                       isDeleteDisabled={this.props.isDeleteDisabled}
                       showRemoveAction={this.state.removing}
                       filesToRemove={this.state.removingFiles}
                       onRemoveAction={this.handleFileRemovingAction}
                       onFileClick={this.handleFileClick}
                       customContextMenuItems={this.props.customFileActions}
                       onContextMenuSelection={this.props.onFileAction && this.handleContextMenuSelection}
                       {...(this.props.layoutComponentProps ?? {})} />
        );
    };

    get toggleLayoutButtonDef(): IToolbarItem {
        return {
            id: LAYOUT_TOGGLE_BUTTON,
            itemType: ToolbarItemType.SegmentedButton,
            itemProps: {
                def: [{
                    id: FileUploaderLayout.Grid,
                    title: this.props.t("Components:FileUploader.Grid"),
                    iconName: "DocMenu"
                }, {
                    id: FileUploaderLayout.List,
                    title: this.props.t("Components:FileUploader.List"),
                    iconName: "DocList"
                }],
                defaultSelectedButtonId: this.currentLayout
            }
        };
    }

    get currentLayout(): string {
        return this.props.layout ?? (this.state.showGrid ? FileUploaderLayout.Grid : FileUploaderLayout.List);
    }

    get layoutComponent() {
        return this.currentLayout === FileUploaderLayout.Grid ? FileGrid : FileList;
    }

    handleLayoutChange = (key: string): void => {
        this.context.changePersonalizationSetting("fileUploaderLayout", key);
        this.setState({ showGrid: key === FileUploaderLayout.Grid });
    };

    handleToolbarChange = (id: string, value: TValue): void => {
        if (id === LAYOUT_TOGGLE_BUTTON) {
            this.handleLayoutChange(value as string);
        } else {
            this.props.onCustomToolbarAction?.(id, { value });
        }
    };

    renderToolbar = () => {
        const hasFiles = this.props.files?.length > 0;
        const predefinedLayout = isDefined(this.props.layout);
        const filesToRemoveCount = Object.keys(this.state.removingFiles ?? {}).length;

        return (
            <>
                <Toolbar staticItems={hasFiles && !predefinedLayout ? [this.toggleLayoutButtonDef] : []}
                         onItemChange={this.handleToolbarChange}>
                    {this.state.removing &&
                        <ConfirmationButtons
                            isDisabled={!filesToRemoveCount}
                            confirmText={getConfirmationActionText(this.props.t("Components:FileUploader.Delete"), filesToRemoveCount)}
                            onCancel={this.stopRemoving}
                            onConfirm={this.handleConfirmRemoveClick}/>
                    }

                    {this.props.customToolbarContent}

                    {hasFiles && !this.props.isReadOnly &&
                        <IconButton
                            hotspotId={"deleteFiles"}
                            title={this.props.t("Components:FileUploader.Delete")}
                            onClick={this.handleRemoveButtonClick}
                            isActive={this.state.removing}
                            isDisabled={this.props.isDeleteDisabled}
                            isTransparent>
                            <BinIcon/>
                        </IconButton>
                    }

                    {this.props.onAttachInboxFiles && !this.props.isReadOnly &&
                        <IconButton
                            hotspotId={"otherFolder"}
                            title={this.props.t("Components:FileUploader.OtherFolder")}
                            onClick={this.handleShowOtherFolder}
                            isDisabled={this.state.removing || this.props.disableOtherFolder}
                            isTransparent>
                            <FolderOtherIcon/>
                        </IconButton>
                    }

                    {!this.props.isReadOnly &&
                            <FileUploadButton
                                    onFileChange={this.props.onNewFiles}
                                    hotspotId={"addFiles"}
                                    iconName={"Add"}
                                    title={this.props.t("Common:General.Add")}
                                    multiple={this.props.multiple}
                                    accept={this.props.accept}
                                    isDecorative={false}
                                    isDisabled={this.state.removing}/>
                    }
                </Toolbar>
            </>
        );
    };

    render() {

        return (
            <FocusManager>
                {({ itemProps, wrapperProps }) => (
                    <StyledFileUploader isHidden={this.props.isHidden} data-testid={TestIds.FileUploader}
                                        className={this.props.className} style={this.props.style}
                                        {...wrapperProps} ref={wrapperProps.ref as React.RefObject<HTMLDivElement>}>
                        {!this.props.isHidden && this.renderToolbar()}
                        <FileDropArea showContent={this.props.files?.length > 0}
                                      additionalContent={this.props.busyIndicator}
                                      isReadOnly={this.props.isReadOnly || this.state.removing}
                                      isLocalDropArea={this.props.isLocalDropArea}
                                      onNewFiles={this.props.onNewFiles}
                                      customDescription={this.props.customDescription}
                                      onDragEnter={this.props.onDragEnter}
                                      onDragLeave={this.props.onDragLeave}
                                      otherFileDropAreas={this.props.otherFileDropAreas}>
                            {this.renderContent(itemProps)}
                        </FileDropArea>
                        {this.state.showOtherFolder &&
                            <OtherFolderWindow onAttachFiles={this.props.onAttachInboxFiles}
                                               onClose={this.handleCloseOtherFolder}
                                               hideInboxFileIds={this.props.hideInboxFileIds}/>
                        }
                    </StyledFileUploader>
                )}
            </FocusManager>
        );
    }
}

export default withBusyIndicator({ passBusyIndicator: true })(withTranslation(["Components", "Common"])(FileUploader));
