import styled, { css } from "styled-components/macro";

import { T_TITLE_4, T_TITLE_4_NORMAL } from "../../global.style";
import { ButtonGroup, StyledButton, StyledButtonGroup, StyledIconButton } from "../button";
import { StyledSegmentedButton } from "../button/SegmentedButton.styles";

const TOOLBAR_HEIGHT = 38;

export const StyledToolbar = styled.div`
    position: relative;
    display: flex;
    min-height: ${`${TOOLBAR_HEIGHT}px`};
    ${T_TITLE_4_NORMAL};
    align-items: center;
    flex-wrap: wrap-reverse;
    width: 100%;
    overflow-x: auto;
`;

export const Content = styled(ButtonGroup)`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    
    & > * {
        flex-shrink: 0;
    }

    & > *:not(:first-child) {
        margin-left: 12px;
    }

    & > ${StyledSegmentedButton}:not(:first-child),
    & > ${StyledButton}:not(${StyledIconButton}):not(:first-child) {
        margin-left: 15px;
    }
`;

export const ContentWrapper = styled.div<{
    isSticky?: boolean;
}>`
    display: flex;
    flex: 1 1 auto;
    position: relative;
    height: ${`${TOOLBAR_HEIGHT}px`};

    & > ${StyledButtonGroup} {
        overflow-y: hidden;
        min-width: ${props => props.isSticky ? "max-content" : null};

        ${StyledButton} {
            white-space: nowrap;
        }
    }
`;

export const StaticItems = styled.div`
    display: flex;
    height: ${`${TOOLBAR_HEIGHT}px`};
    align-items: center;

    & > *:last-child {
        margin-right: 25px;
    }
`;

export const IconWrapper = styled.div`
    margin-left: 10px;
    display: none;
`;

export const StyledWriteLine = styled.div<{ maxWidth?: string; isResponsive?: boolean; }>`
    display: flex;
    position: relative;
    top: 2px;
    ${props => props.maxWidth && `max-width: ${props.maxWidth};`}

    ${props => props.isResponsive && css`
        // support responsiveness
        flex: 1 1 auto;
    `};
`;

export const WriteLineLabel = styled.span`
    ${T_TITLE_4}
`;