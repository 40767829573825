import { ISelectItem } from "@components/inputs/select/Select.types";
import { IFieldDef, IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import { StyledTooltipContent } from "@components/tooltipIcon/TooltipIcon.styles";
import {
    DocumentEntity,
    EntitySetName,
    IDocumentDraftEntity,
    IDocumentEntity,
    NumberRangeEntity
} from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { SAVED_VATS_PATH } from "@pages/admin/vatRules/VatRules.utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { arrayInsert, isObjectEmpty } from "@utils/general";
import { Dayjs } from "dayjs";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import React, { ReactElement } from "react";

import Field from "../../components/inputs/field";
import Input from "../../components/inputs/input/Input";
import { BasicInputSizes, FieldType, PageViewMode } from "../../enums";
import DateType, { getUtcDayjs } from "../../types/Date";
import { FormStorage } from "../../views/formView/FormStorage";
import { CreateCustomAssignmentPath, ItemCreateCustomAssignmentBc } from "../accountAssignment/AccountAssignment.utils";
import { SAVED_ACCOUNTS_PATH } from "../banks/bankAccounts/BankAccounts.utils";
import { commonDocumentTranslations } from "../documents/Document.utils";
import { addBusinessPartnerFieldDefs, addCommonDocumentDefs } from "../documents/DocumentCommonDefs";
import { getDefinitions as getDocumentDefinitions } from "../documents/DocumentDef";
import InvoicesIssuedFormView from "../documents/invoicesIssued/InvoicesIssuedFormView";
import InvoicesReceivedFormView from "../documents/invoicesReceived/InvoicesReceivedFormView";
import { IDefinition, IGetDefinition } from "../PageUtils";
import { InfoIconStyled } from "./RecurringTask.styles";
import { IRecurringTaskCustomData } from "./RecurringTask.utils";

const getItemBreadCrumbText = () => {
    return "";
};

const getTooltipIcon = (date: Date | Dayjs): ReactElement => {
    return <InfoIconStyled>
        <StyledTooltipContent>{DateType.format(date)}</StyledTooltipContent>
    </InfoIconStyled>;
};

export const getDefinitions: IGetDefinition = (context, type: DocumentTypeCode, isForPreview?: boolean): IDefinition => {
    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.DocumentDrafts,
        documentType: type,
        tableId: "recurringTaskDraftSpecialTable",
        formId: "recurringTaskDraftSpecialForm",
        formControl: type === DocumentTypeCode.InvoiceIssued ? InvoicesIssuedFormView : InvoicesReceivedFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText,
        context
    }));

    addCommonDocumentDefs(definition, type, context);

    delete definition.form.summary;

    definition.form.summary = definition.form.summary?.filter(s => !s.id.startsWith("ClearedStatus"));


    definition.form.additionalProperties = [
        ...definition.form.additionalProperties.filter(p => !p.id.startsWith("DocumentDraft") && p.id !== DocumentEntity.ClearedStatus && p.id !== DocumentEntity.DocumentVatStatementStatus),
        { id: "DocumentTypeCode" }
    ];

    definition.form.fieldDefinition.DocumentTypeCode = {
        label: i18next.t("RecurringTasks:Type"),
        isVisible: args => {
            return (args.storage as FormStorage).pageViewMode === PageViewMode.FormReadOnly;
        },
        fieldSettings: {
            formatter: value => {
                return i18next.t(`RecurringTasks:TaskDialog.${value === DocumentTypeCode.InvoiceIssued ? "InvoiceIssued" : "InvoiceReceived"}`);
            }
        }
    };

    definition.form.fieldDefinition.NumberRange = {
        type: FieldType.ComboBox,
        fieldSettings: {
            additionalProperties: [{ id: NumberRangeEntity.NextNumber }, { id: NumberRangeEntity.Definition }],
            displayName: NumberRangeEntity.NextNumber,
            transformFetchedItems: (items) => {
                return items.reduce((uniqueItems: ISelectItem[], item) => {
                    // for recurring tasks we are actually selecting only definition through number range
                    if (!uniqueItems.find(i => i.additionalData.Definition?.Id === item.additionalData.Definition?.Id)) {
                        uniqueItems.push(item);
                    }
                    return uniqueItems;
                }, []);
            }
        },
        formatter: (val, args) => {
            const entity = args.entity as IDocumentEntity;
            return entity?.NumberRange?.NextNumber;
        },
        width: BasicInputSizes.XL,
        customizationData: {
            useForCustomization: false
        },
        filter: {
            select: () => {
                return `Definition/IsActive eq true AND Definition/NumberRangeType/DocumentTypeCode eq '${type}'`;
            }
        }
    };

    if (!isCashBasisAccountingCompany(context)) {
        definition.form.fieldDefinition.ExchangeRatePerUnit.isVisible = false;
        definition.form.fieldDefinition[CreateCustomAssignmentPath].isVisible = false;
        definition.form.fieldDefinition[`Items/AccountAssignmentSelection/AccountAssignment/${ItemCreateCustomAssignmentBc}`].isVisible = false;
    }

    addBusinessPartnerFieldDefs(definition, type, type === DocumentTypeCode.InvoiceIssued ? "InvoicesIssued" : "InvoicesReceived");
    delete definition.form.fieldDefinition["BusinessPartner/LegalNumber"].collapsedRows;
    delete definition.form.fieldDefinition["BusinessPartner/LegalNumber"].creatingTitle;

    definition.form.showChanges = false;

    for (const [key, fieldDef] of Object.entries(definition.form.fieldDefinition)) {
        fieldDef.isRequired = false;

        if (key === SAVED_ACCOUNTS_PATH) {
            fieldDef.fieldSettings.additionalItems = [];
        }
    }

    if (isForPreview) {
        definition.form.isReadOnly = true;

        if (!isCashBasisAccountingCompany(context)) {
            definition.form.fieldDefinition.DateAccountingTransaction = {
                type: FieldType.Custom,
                render: (args) => {
                    return <Field hasPadding label={i18next.t("RecurringTasks:DateAccountingTransaction")}>
                        <Input value={i18next.t("RecurringTasks:DateIssued")}
                               isReadOnly={true}
                        />
                        {getTooltipIcon((args.storage as FormStorage<unknown, IRecurringTaskCustomData>).getCustomData().DateNextExecution)}
                    </Field>;
                }
            };
        }

        definition.form.fieldDefinition.DateReceived = {
            type: FieldType.Custom,
            render: (args: IFieldDefFn) => {
                return <Field hasPadding label={i18next.t("RecurringTasks:DateReceived")}>
                    <Input value={i18next.t("RecurringTasks:DateIssued")}
                           isReadOnly={true}
                    />
                    {getTooltipIcon((args.storage as FormStorage<unknown, IRecurringTaskCustomData>).getCustomData().DateNextExecution)}
                </Field>;
            }
        };

        definition.form.fieldDefinition.DateDue = {
            type: FieldType.Custom,
            render: (args) => {
                const entity = args.storage.data.entity as IDocumentDraftEntity;
                const date = getUtcDayjs((args.storage as FormStorage<unknown, IRecurringTaskCustomData>).getCustomData().DateNextExecution).add(14, "d");
                return <Field hasPadding label={i18next.t("RecurringTasks:DateDue")}>
                    <Input
                        value={i18next.t(`RecurringTasks:DateDueDesc${entity.DocumentTypeCode === DocumentTypeCode.InvoiceReceived ? "Received" : "Issued"}`)}
                        isReadOnly={true}
                    />
                    {getTooltipIcon(date)}
                </Field>;
            }
        };

        definition.form.fieldDefinition.DateIssued = {
            type: FieldType.Custom,
            render: (args) => {
                return <Field hasPadding label={i18next.t("RecurringTasks:Issued")}>
                    <Input value={i18next.t("RecurringTasks:ScheduledStartDate")}
                           isReadOnly={true}
                    />
                    {getTooltipIcon((args.storage as FormStorage<unknown, IRecurringTaskCustomData>).getCustomData().DateNextExecution)}
                </Field>;
            }
        };

        const docGroupIndex = definition.form.groups.findIndex(g => g.id === "document");
        definition.form.groups = arrayInsert(definition.form.groups, {
            id: "Date",
            title: i18next.t("Document:FormGroup.Date"),
            rows: [
                !isCashBasisAccountingCompany(context) ? [{ id: "DateAccountingTransaction" }] : [],
                [{ id: "DateReceived" }, { id: "DateDue" }, { id: "DateIssued" }]
            ]
        }, (docGroupIndex + 1));

        definition.form.groups = definition.form.groups.map(group => {
            group.showGroupDivider = false;
            return group;
        });

        for (const fieldDef of Object.values(definition.form.fieldDefinition)) {
            const origIsVisible = fieldDef.isVisible;
            fieldDef.isVisible = (args: IGetValueArgs) => {
                const val = args.bindingContext ? args.storage.getValue(args.bindingContext) : args.storage.getValueByPath((args.info as IFieldDef)?.id);
                const isItemProp = args.bindingContext?.getParentCollection(args.storage.data.bindingContext)?.getPath() === "Items";
                const isWithoutValue = typeof val === "object" ? isObjectEmpty(val) : !val;
                if (isWithoutValue && !isItemProp && fieldDef.type !== FieldType.Custom && args.bindingContext.getPath() !== SAVED_VATS_PATH) {
                    return false;
                }
                if (typeof origIsVisible === "function") {
                    return origIsVisible(args);
                }
                return origIsVisible;
            };
        }
    }

    definition.form.groups = [{
        id: "numRange",
        rows: [[{ id: "DocumentTypeCode" }, { id: "NumberRange" }]]
    }, ...definition.form.groups.filter(g => !["date", "Tabs"].includes(g.id))
    ];

    return definition;
};

getDefinitions.translationFiles = [...commonDocumentTranslations, "InvoicesIssued", "InvoicesReceived", "RecurringTasks"];