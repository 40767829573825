import { WithAlert } from "@components/alert/withAlert";
import { IColumnContent, IListItem, ISection } from "@components/objectList";
import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import BindingContext, { createBindingContext, IEntity, TEntityKey } from "@odata/BindingContext";
import { ODataError } from "@odata/Data.types";
import { WithOData } from "@odata/withOData";
import ObjectListPage from "@pages/ObjectListPage";
import Page, { IPageParams, IPageState } from "@pages/Page";
import {
    deleteObjectListItem,
    getStandardObjectListActions,
    IDefinition,
    IPageProps,
    ObjectListAction
} from "@pages/PageUtils";
import { isVisibleInContainer, scrollIntoView } from "@utils/domUtils";
import { sortCompareBooleanFn } from "@utils/general";
import { anyPartStartsWithAccentsInsensitive, compareString } from "@utils/string";
import i18next from "i18next";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { FILES_API_URL, NEW_ITEM_DETAIL } from "../constants";
import { AppContext, IAppContext } from "../contexts/appContext/AppContext.types";
import { QueryParam, Status } from "../enums";
import { getQueryParameters } from "../routes/Routes.utils";
import { PropsWithTheme } from "../theme";
import DialogFormView from "./formView/DialogFormView";
import { FormStorage } from "./formView/FormStorage";

export interface IObjectListProps extends WithAlert, WithOData, WithTranslation, RouteComponentProps, IPageProps, PropsWithTheme {
    entitySet: string;
    getDef: (context: IAppContext) => IDefinition;
    translationPath: string;
    id: string;
    itemIconName?: string;
    width?: string;
    height?: string;
}

export interface IObjectListState extends IPageState {
    searchTerm: string;
}

export abstract class ObjectListView<T extends Partial<{
    Name: string,
    Id: TEntityKey,
    Logo: IEntity,
    IsActive: boolean,
    IsDefault: boolean
}>, C> extends Page<IObjectListProps, IObjectListState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    protected _refFormView = React.createRef<any>();
    protected abstract _formStorageId: string;
    _scrollRef = React.createRef<HTMLDivElement>();
    _isCustomImageSvg = false;

    storage: FormStorage<T, C>;
    loading: boolean;
    _scrollToBc: BindingContext;
    formView: typeof DialogFormView = DialogFormView;

    data: T[] = [];

    state: IObjectListState = {
        searchTerm: ""
    };

    constructor(props: IObjectListProps, context: IAppContext) {
        super(props, context);

        this.renderContents = this.renderContents.bind(this);
        this.createItems = this.createItems.bind(this);
        this.getFilteredItems = this.getFilteredItems.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();

        this.storage = new FormStorage({
            id: this._formStorageId,
            oData: this.props.oData,
            t: this.props.t,
            history: this.props.history,
            match: this.props.match,
            theme: this.props.theme,
            context: this.context,
            refresh: () => {
                this._refFormView.current?.forceUpdate();
            }
        });
    }

    async componentDidUpdate(prevProps: Readonly<IObjectListProps>, prevState: Readonly<IObjectListState>) {
        super.componentDidUpdate(prevProps, prevState);

        const shouldReload = !!this.getKey() && ((!this.storage.loaded && this.detailData?.bc && !this.loading) || this.getKey() !== (prevProps.match?.params as IPageParams)?.Id);

        if (shouldReload) {
            this.loading = true;
            await this.storage.init({
                bindingContext: this.detailData.bc,
                definition: this.detailData.def
            });
            this.loading = false;
            this.refreshPage(this.detailData.bc);
        }
    }

    get searchPlaceholder(): string {
        return this.props.t("Common:General.Search");
    }

    renderContents(item: T): IColumnContent[] {
        return [];
    }

    refreshPage(scrollToBc: BindingContext): void {
        this._scrollToBc = scrollToBc;
        this.forceUpdate(() => {
            this._scrollToBc && this.scrollToItem(this._scrollToBc.getKey());
            this._scrollToBc = null;
        });
    }

    createItemSubTitle = (item: T): React.ReactElement => {
        return null;
    };

    createItems(items: T[]): IListItem[] {
        return (items || []).map((item, idx) => {
            const logoId = item?.Logo?.Id;
            const id = item.Id?.toString();
            return {
                id,
                // if we scroll manually to the listItem, we need to use index as a key,
                // so react don't scroll automatically (without animation)
                key: !!this._scrollToBc ? idx.toString() : id,
                name: item.Name,
                subTitle: this.createItemSubTitle(item),
                isDefault: item.IsDefault,
                iconName: logoId ? "" : this.props.itemIconName,
                iconTitle: item.Name,
                customImageSrc: logoId ? `${FILES_API_URL}/${logoId}` : null,
                isCustomImageSvg: this._isCustomImageSvg,
                actions: [...getStandardObjectListActions(this.storage.t, item.IsActive, item.IsDefault), ...this.customActions(item)],
                contents: this.renderContents(item)
            };
        });
    }

    getFilteredItems(items: T[]): T[] {
        const filteredItems = (this.state.searchTerm ? items.filter((item) => {
            return anyPartStartsWithAccentsInsensitive(item.Name, this.state.searchTerm);
        }) : items) ?? [];
        return filteredItems.sort((a, b) => {
            if (a.IsDefault !== b.IsDefault) {
                return sortCompareBooleanFn(a.IsDefault, b.IsDefault);
            }

            if (a.IsActive !== b.IsActive) {
                return sortCompareBooleanFn(a.IsActive, b.IsActive);
            }
            return compareString(a.Name, b.Name);
        });
    }

    showMessage = (status: Status, subTitle: string): void => {
        this.props.setAlert({
            title: status === Status.Error ? this.props.t("Components:ObjectList.Error") : this.props.t("Common:Validation.SuccessTitle"),
            status, subTitle
        });
    };

    showError = (e: ODataError, id: TEntityKey): void => {
        const code = e._validationMessages?.[0]?.code;
        const message = code ? this.props.t(`Error:${code}`) : e._validationMessages?.[0]?.message ?? this.props.t("Common:Errors.ErrorHappened");

        this.props.setAlert({
            status: Status.Error,
            subTitle: message,
            title: this.props.t("Components:ObjectList.Error")
        });
    };

    customActions = (item: T): IToolbarItem[] => {
        return [];
    };

    handleFilterChange = (searchTerm: string): void => {
        this.setState({
            searchTerm
        });
    };

    handleCustomAction = async (itemId: TEntityKey, type: string): Promise<void> => {
        return null;
    };

    handleAction = async (itemId: TEntityKey, type: string): Promise<void> => {
        switch (type) {
            case ObjectListAction.Edit:
                this.setUrl(itemId as string);
                break;

            case ObjectListAction.ToggleActive:
                const item = this.data.find(item => item.Id?.toString() === itemId?.toString());
                if (item) {
                    const updateData = {
                        IsActive: !item.IsActive
                    };
                    let bc: BindingContext;
                    try {
                        await this.props.oData.fromPath(this.props.entitySet).update(itemId, updateData);

                        item.IsActive = !item.IsActive;
                        this.data = [...this.data];
                        bc = createBindingContext(this.props.entitySet, this.props.oData.metadata).addKey(itemId);
                        await this.onAfterSave(bc, true);
                    } catch (e) {
                        this.showError(e, itemId);
                    }

                    this.refreshPage(bc);
                }
                break;
            default:
                await this.handleCustomAction(itemId, type);
        }
    };

    handleCloseDialog = (): void => {
        this.setUrl("");
        delete this.storage.data.alert;
        this.storage.loaded = false;
    };

    getSaveMessage = (id: TEntityKey, isNew: boolean): string => {
        return "";
    };

    handleConfirmDialog = async (isNew: boolean): Promise<void> => {
        const key = this.storage.data.bindingContext.getKey();
        await this.onAfterSave(this.detailData?.bc);
        this.handleCloseDialog();
        this.props.setAlert({
            status: Status.Success,
            title: i18next.t("Common:Validation.SuccessTitle"),
            subTitle: this.getSaveMessage(key, isNew)
        });

        this.scrollToItem(key);
    };

    scrollToItem = (id: TEntityKey): void => {
        const item = document.querySelector(`[data-listitemid="${id}"]`) as HTMLElement;

        if (this._scrollRef.current && item && !isVisibleInContainer(this._scrollRef.current, item)) {
            scrollIntoView(this._scrollRef.current, item, { behavior: "smooth", block: "center" });

            item.focus({
                preventScroll: true
            });
        }
    };

    abstract onAfterSave(bc: BindingContext, refreshList?: boolean): Promise<void>;

    onAfterDelete = (item: T): Promise<void> => {
        return Promise.resolve(undefined);
    };

    handleAdd = (): void => {
        this.setUrl(NEW_ITEM_DETAIL);
    };

    get showDialog(): boolean {
        const action = getQueryParameters()?.[QueryParam.Action];
        return !!this.getKey() && !action;
    }

    handleDelete = async (id: TEntityKey): Promise<void> => {
        try {
            const item = this.data?.find(item => item.Id?.toString() === id?.toString());
            await deleteObjectListItem(this.props.entitySet, this.props.oData, this.data, id);
            await this.onAfterDelete(item);
        } catch (e) {
            this.scrollToItem(id);
            this.showError(e, id);
        }
        this.forceUpdate();
    };

    customRender = (): React.ReactElement => {
        return undefined;
    };

    getSections = (data: T[]): ISection[] => {
        return [{
            id: "main",
            children: this.createItems(this.getFilteredItems(data))
        }];
    };

    render() {
        if (!this.isReady()) {
            return null;
        }
        const FormViewComponent = this.formView;
        const isNew = this.detailData?.bc?.isNew();

        return (
            <>
                <ObjectListPage
                    searchTerm={this.state.searchTerm}
                    searchPlaceholder={this.searchPlaceholder}
                    alert={this.props.alert}
                    sections={this.getSections(this.data)}
                    onAdd={this.handleAdd}
                    onHandleAction={this.handleAction}
                    onDelete={this.handleDelete}
                    id={this.props.id}
                    scrollRef={this._scrollRef}
                    onFilter={this.handleFilterChange}
                    title={this.props.t(`${this.props.translationPath}Title`)}/>

                {this.showDialog &&
                    <FormViewComponent
                        ref={this._refFormView}
                        storage={this.storage as any}
                        onClose={this.handleCloseDialog}
                        onAfterSave={this.handleConfirmDialog}
                        confirmText={this.props.t(`Common:General.${isNew ? "Create" : "Save"}`).toString()}
                        dialogProps={{
                            title: isNew ? this.props.t(`${this.props.translationPath}NewObjectItem`) :
                                this.props.t(`${this.props.translationPath}EditObjectItem`)
                        }}
                    />}
                {this.customRender()}
            </>
        );
    }
}